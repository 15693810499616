
import { concatClassCSS } from "@/utils";
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  watchEffect,
} from "vue";
import { VariantType, CardType, ShadowType, CardClass } from "./index";

export default defineComponent({
  name: "b-card",
  emits: ["click"],
  props: {
    /**
     * Variant: Theme de la card
     * @default "primary"
     * @type VariantCard
     */
    variant: {
      type: String as PropType<VariantType>,
      required: false,
      default: () => "primary",
    },
    type: {
      type: String as PropType<CardType>,
      required: false,
      default: () => "rounded",
    },
    shadow: {
      type: String as PropType<ShadowType>,
      required: false,
      default: () => "md",
    },
    outlined: Boolean,
    disabled: Boolean,
    /**
     * Affiche une barre de chargement
     */
    loading: Boolean,
    hoverable: Boolean,
    activable: Boolean,

    override: {
      type: Object as PropType<CardClass>,
      required: false,
      default: () => null,
    },
    optClass: {
      type: String,
      required: false,
    }
  },
  setup(props, { slots, emit }) {
    const cardClass = reactive<CardClass>({});

    const updateClass = () => {
      cardClass.main =
        "flex flex-col flex-grow overflow-hidden transition-all duration-200";
      cardClass.header = "px-3.5 py-2.5 text-base font-semibold";
      cardClass.footer = "px-3.5 py-2.5 text-sm";
      cardClass.content = "px-2 flex-1 py-1.5 overflow-auto text-justify";
      cardClass.loading = "loading";
      cardClass.loadingBar = "loading-bar";

      // Card
      cardClass.main = concatClassCSS(
        cardClass.main,
        getCardVariantClass(props.variant, props.outlined),
        getTypeClass(props.type),
        getShadowClass(props.shadow),
        getOptionClass(
          props.hoverable,
          props.disabled,
          props.activable,
          props.loading
        )
      );

      cardClass.header = concatClassCSS(
        cardClass.header,
        getHeaderVariantClass(props.variant)
      );
      cardClass.footer = concatClassCSS(
        cardClass.footer,
        getFooterVariantClass(props.variant)
      );

      cardClass.loading = concatClassCSS(
        cardClass.loading,
        getLoadingClass(props.variant, props.loading)
      );
      cardClass.loadingBar = concatClassCSS(
        cardClass.loadingBar,
        getLoadingBarClass(props.variant, props.loading)
      );

      // Overrides
      if (props.override) {
        const override: CardClass = props.override;
        if (override.header) {
          cardClass.header = override.header;
        }
        if (override.footer) {
          cardClass.footer = override.footer;
        }
        if (override.main) {
          cardClass.main = override.main;
        }
        if (override.content) {
          cardClass.content = override.content;
        }
      }
    };

    const getCardVariantClass = (variant: VariantType, outlined: boolean) => {
      let Class = "";
      switch (variant) {
        case "primary":
          if (outlined) {
            Class = "border border-primary bg-element";
          } else {
            Class = "bg-element";
          }
          break;

        case "secondary":
          if (outlined) {
            Class = "border border-secondary bg-element";
          } else {
            Class = "bg-element";
          }
          break;

        case "danger":
          if (outlined) {
            Class = "border border-danger bg-element";
          } else {
            Class = "bg-element";
          }
          break;

        case "success":
          if (outlined) {
            Class = "border border-success bg-element";
          } else {
            Class = "bg-element";
          }
          break;

        case "dark":
          if (outlined) {
            Class = "border border-dark bg-element";
          } else {
            Class = "bg-element";
          }
          break;

        case "light":
          if (outlined) {
            Class = "border border-light dark:border-dark bg-element";
          } else {
            Class = "bg-element";
          }
          break;

        case "transparent":
          if (outlined) {
            Class = "border border-black bg-transparent";
          } else {
            Class = "bg-transparent";
          }
          break;
      }
      return Class;
    };

    const getHeaderVariantClass = (variant: VariantType) => {
      let Class = "";
      switch (variant) {
        case "primary":
          Class = "text-primary";
          break;

        case "secondary":
          Class = "text-secondary";
          break;

        case "danger":
          Class = "text-danger";
          break;

        case "success":
          Class = "text-success";
          break;

        case "dark":
          Class = "text-dark";
          break;

        case "light":
          Class = "";
          break;

        case "transparent":
          Class = "";
          break;
      }
      return Class;
    };

    const getFooterVariantClass = (variant: VariantType) => {
      let className = "";
      switch (variant) {
        case "primary":
          className = "text-primary bg-primary-light dark:bg-primary-dark";
          break;

        case "secondary":
          className = "text-secondary bg-secondary-light";
          break;

        case "danger":
          className = "text-danger bg-danger-light";
          break;

        case "success":
          className = "text-success bg-success-light";
          break;

        case "dark":
          className = "text-light bg-dark";
          break;

        case "light":
          className = "text-dark bg-light dark:bg-dark";
          break;

        case "transparent":
          className = "bg-transparent";
          break;
      }
      return className;
    };

    const getShadowClass = (shadow: ShadowType) => {
      let Class = "";
      switch (shadow) {
        case "sm-light":
          Class = "shadow-sm-light";
          break;
        case "sm":
          Class = "shadow-sm";
          break;
        case "md":
          Class = "shadow-md";
          break;
        case "lg":
          Class = "shadow-lg";
          break;
        case "xl":
          Class = "shadow-xl";
          break;
        case "2xl":
          Class = "shadow-2xl";
          break;
        case "inner":
          Class = "shadow-inner";
          break;
        case "none":
          Class = "shadow-none";
          break;
      }
      return Class;
    };

    const getTypeClass = (type: CardType) => {
      let Class = "";
      switch (type) {
        case "rounded":
          Class = "rounded-lg";
          break;
        case "shaped":
          Class = "rounded-tl-2xl rounded-br-2xl";
          break;
        case "tile":
          Class = "rounded-none";
          break;
      }
      return Class;
    };

    const getOptionClass = (
      hoverable: boolean,
      disabled: boolean,
      activable: boolean,
      loading: boolean
    ) => {
      let Class = "";
      if (disabled || loading) {
        if (loading) {
          Class = "cursor-wait opacity-75 animate-pulse";
        }
        if (disabled) {
          Class = "cursor-not-allowed opacity-60";
        }
      } else {
        if (hoverable) {
          Class += " hover:scale-95 hover:opacity-98";
        }

        if (activable) {
          Class += ` cursor-pointer active:opacity-95 active:ring-4 active:ring-${props.variant} active:outline-none`;
        }
      }
      return Class;
    };

    const getLoadingClass = (variant: VariantType, loading: boolean) => {
      let Class = "";
      if (!loading) {
        Class = "h-1";
      } else {
        switch (variant) {
          case "primary":
            Class = "bg-primary-light";
            break;

          case "secondary":
            Class = "bg-secondary-light";
            break;

          case "danger":
            Class = "bg-danger-light";
            break;

          case "success":
            Class = "bg-success-light";
            break;

          case "dark":
            Class = "bg-dark";
            break;

          case "light":
            Class = "bg-light";
            break;

          case "transparent":
            Class = "bg-white";
            break;
        }
      }
      return Class;
    };

    const getLoadingBarClass = (variant: VariantType, loading: boolean) => {
      let Class = "";
      if (!loading) {
        Class = "h-1";
      } else {
        switch (variant) {
          case "primary":
            Class = "bg-primary";
            break;

          case "secondary":
            Class = "bg-secondary";
            break;

          case "danger":
            Class = "bg-danger";
            break;

          case "success":
            Class = "bg-success";
            break;

          case "dark":
            Class = "bg-light";
            break;

          case "light":
            Class = "bg-dark";
            break;

          case "transparent":
            Class = "bg-black";
            break;
        }
      }
      return Class;
    };

    /**
     * Regarde si il y a un slot header
     */
    const hasHeaderSlot = computed<boolean>(() => {
      return !!slots.header;
    });

    /**
     * Regarde si il y a un slot footer
     */
    const hasFooterSlot = computed<boolean>(() => {
      return !!slots.footer;
    });

    /**
     * Regarde si il y a un slot title
     */
    const hasTitleSlot = computed<boolean>(() => {
      return !!slots.title;
    });

    watchEffect(() => updateClass());

    return {
      cardClass,

      hasHeaderSlot,
      hasFooterSlot,
      hasTitleSlot,

      handleClick: (event: MouseEvent) =>
        !props.loading && !props.disabled && emit("click", event),
    };
  },
});
